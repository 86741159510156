
import AvatarImage from "@/components/AvatarImage.vue";
import { PopoutsModule } from "@/store/modules/popouts";
import User from "@/interfaces/User";
import CustomButton from "@/components/CustomButton.vue";
import CustomInput from "@/components/CustomInput.vue";
import CheckBox from "@/components/CheckBox.vue";
import { unsuspendUser } from "@/services/adminService";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ProfilePopout",
  components: { AvatarImage, CustomButton, CustomInput, CheckBox },
  props: {
    data: {
      type: Object as PropType<{
        id: string;
        serverID: string;
        user: User;
        callback: any;
        users: User[];
      }>,
      required: true,
    },
  },
  data() {
    return {
      reason: "Violating the TOS.",
      password: "",
      requestSent: false,
      removeIPBan: true,
      error: null as string | null,
    };
  },
  computed: {
    user(): any {
      return this.data.user;
    },
  },
  methods: {
    close() {
      PopoutsModule.ClosePopout("admin-unsuspend-user-popout");
    },
    backgroundClick(event: any) {
      if (event.target.classList.contains("popout-background")) {
        this.close();
      }
    },
    buttonClicked() {
      if (this.requestSent) return;
      this.requestSent = true;
      this.error = null;
      if (this.data.users) {
        this.unsuspendBatch();
        return;
      }
      unsuspendUser(this.data.user.id, this.password, this.removeIPBan)
        .then(() => {
          this.data.callback({ removeIPBan: this.removeIPBan });
          this.close();
        })
        .catch(async (err) => {
          if (!err.response) {
            this.error = "Could not connect to server.";
            return;
          }
          const { message } = await err.response.json();
          this.error = message;
        })
        .finally(() => (this.requestSent = false));
    },
    unsuspendBatch(index = 0) {
      const user = this.data.users[index];
      unsuspendUser(user.id, this.password, this.removeIPBan)
        .then(() => {
          this.data.callback({ removeIPBan: this.removeIPBan, user });
          if (index + 1 === this.data.users.length) {
            this.close();
            return;
          }
          this.unsuspendBatch(index + 1);
        })
        .catch(async (err) => {
          this.requestSent = false;
          if (!err.response) {
            this.error = "Could not connect to server.";
            return;
          }
          const { message } = await err.response.json();
          this.error = message;
        });
    },
  },
});
